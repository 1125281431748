@import 'variable';

body {
    .partner-campaigns-listing-section {
        .partner-campaigns-listing-container {
            padding: 0;

            .MuiAutocomplete-root {
                width: unset;
            }

            .heading {
                position: relative;

                .button-wrapper {
                    position: absolute;
                    right: 0;
                    top: 0;

                    a {
                        display: inline-block;

                        img,
                        span {
                            vertical-align: middle;
                        }

                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }


            .partner-campaigns-listing-table {
                tbody {
                    tr {
                        position: relative;

                        &.open {
                            vertical-align: top;

                            td {
                                padding-top: 12px;
                                .more-icon {
                                    top: -5px;
                                }

                                .cnp-action-button-wrapper{
                                    margin-top: -5px;
                                }
                            }
                        }

                        td {
                            height: 40px;
                            padding-top: 0;
                            padding-bottom: 0;

                            &.ellipsis {
                                max-width: 150px;
                                word-break: keep-all;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;

                                span {
                                    word-break: keep-all;
                                }
                            }

                            .more-icon {
                                cursor: pointer;
                                position: relative;
                                left: 10px;

                                &.active {
                                    color: $color_red;
                                }
                            }

                            .cnp-action-button-wrapper {
                                display: flex;
                                align-items: center;

                                .shared-to-status {
                                    font-weight: 500;
                                    width: 90px;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-right: 7px;
                                    }
                                }

                                .elect-btn {
                                    padding: 5px 10px;
                                    height: 24px;
                                    font-size: 12px;
                                    line-height: 1.17;
                                    font-weight: 500;
                                }

                                .reject-btn {
                                    margin-left: 5px;
                                    padding: 5px 10px;
                                    height: 24px;
                                    font-size: 12px;
                                    line-height: 1.17;
                                    font-weight: 500;
                                    background: #68737A;
                                    border-color: #68737A;
                                }
                            }

                            .carrier-term{
                                position: absolute;
                                left: 0;
                                top: 40px;
                                width: calc(100% - 20px);
                                text-align: left;
                                padding: 12px 0;
                                margin: 0 16px;
                                border-top: 1px solid #C0CCD4;
                                display: grid;
                                grid-template-columns: repeat(3, 1fr);
                                row-gap: 9px;
                                > * {
                                    .paragraph{
                                        font-size: $font12;
                                        line-height: $font16;
                                        border-right: 1px solid rgba(104, 115, 122, 0.2);
                                        padding-left: 74px;
                                        .value{
                                            color: $color_text;
                                            font-weight: 700;
                                            margin-left: 4px;
                                        }
                                        .mno-name {
                                            color: $color_red;
                                            margin-right: 2px;
                                        }
                                    }
                                    &:last-child {
                                        .paragraph {
                                            border-right: none;
                                        }
                                    }
                                    &:nth-child(3n+1) {
                                        .paragraph {
                                            padding-left: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &:hover {
                            cursor: text;

                            td {
                                .more-icon {
                                    color: $color_red
                                }
                            }
                        }

                        &.mno-status-info-row {
                            cursor: text;
                            border-bottom: 1px solid $color_C0CCD4;

                            &:hover {
                                transform: scale(1);
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}