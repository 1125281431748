@import "variable";

body {
  .knowledge-base-section {
    background: #F2F2F2;
    margin: -30px -30px -25px -25px;

    .docs-tab-wrapper {
      padding-left: 25px;
      background: rgba(0, 121, 124, 0.1);

      .knowledgebase-tab {
        .MuiTab-root {
          padding-left: 0;
          padding-right: 20px;
          text-transform: none;
          min-width: unset;
          padding-top: 8px;
        }
      }

      .MuiTabs-indicator {
        background-color: transparent !important;
      }

      .MuiTabs-root {
        min-height: 40px;
      }

      .MuiTab-root {
        min-height: unset;
        font-family: 'Roboto';
        color: #00797C;
        font-size: 14px;
        font-weight: 400;
      }

      .MuiTab-textColorPrimary.Mui-selected {
        font-weight: 700;
        color: #00797C;
      }
    }

    .tab-panel-wrapper {
      background: #fff;
      padding: 10px 30px 30px 25px;
      overflow: auto;
      color: #49535D;
      line-height: 21px;

      @mixin header($fz: 26px, $mt: 24px, $mb: 18px, $lh: 31px) {
        font-size: $fz;
        margin-top: $mt;
        margin-bottom: $mb;
        font-weight: 700;
        line-height: $lh;
        color: #49535D;
      }

      h1 {
        @include header;
      }
      h2 {
        @include header(21px, 24px, 14px, 26px);
      }
      h3 {
        @include header(18px, 18px, 14px, 23px);
      }
      h4, h5, h6 {
        @include header(14px, 18px, 14px, 19px);
      }
      table {
        border-collapse: collapse;
        margin: 24px 0;

        td,th {
          border: 1px solid #898EA4;
          text-align: start;
          padding: 8px;
          vertical-align: top;
          line-height: 19px;
        }
        thead {
          th, td {
            background-color: #E5EFF2;
            font-weight: bold;
          }
        }
      }
      b, strong {
        font-weight: 700;
      }
      p {
        margin-top: 14px;
        margin-bottom: 14px;
        line-height: 21px;

        &.hidden {
          display: none;
        }
      }
      a {
        color: #0091b3;
        &:hover {
          text-decoration: underline;
        }
        &:visited {
          text-decoration: none;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }

      ol, ul {
        padding: revert;
        margin: revert;
        li {
          line-height: 21px;
          margin-bottom: 7px;
        }
      }

      ul {
        li {
          p, .content p {
            margin-top: 0;
          }
        }
      }

      ol {
        li {
          ul {
            margin-top: 16px;
            margin-bottom: 16px;
          }
        }
      }

      .section-wrapper {
        // margin-bottom: 5px;
        // padding-top: 20px;

        .subsectionsList {
          margin-top: 20px;

          .subsectionTitle {
            color: #49535D;
            font-weight: 700;
          }
        }
      }
    }

    .doc-summary-blk {
      background: #F2F2F2;
      height: 100%;
      width: 100%;

      .title {
        background: #00797C;
        padding: 12px 15px;
        align-self: center;
        box-sizing: border-box;
        height: 40px;

        h3 {
          @include elementStyles($color: #fff,
            $size: 14px,
          )
        }
      }

      .content-blk {
        padding: 20px 0 0 15px;
        overflow: auto;

        ul {
          li {
            margin-bottom: 15px;
            position: relative;

            span {
              position: absolute;
              color: #49535D;
              font-size: 14px;
              font-weight: 700;
            }

            a {
              text-decoration: none;
              // padding-left: 20px;
              display: inline-block;
              @include elementStyles($size: 14px,
                $color: #49535D,
                $margin: 0 0 0 4px,
                $weight: 400)
            }

            .MuiAccordion-root {
              box-shadow: none;
              background-color: #F2F2F2;
              // margin-left: 20px;

              &:before {
                height: 0;
              }

              .MuiSvgIcon-root {
                width: 16px;
              }

              .MuiAccordionSummary-root {
                min-height: unset;
                font-family: 'Roboto';
                font-size: 14px;
                padding: 0;
                display: inline-flex;
              }

              .MuiIconButton-root {
                padding: 0 10px 0px;
              }

              .MuiAccordionSummary-content {
                margin: -2px 0 0px 5px;
                color: #49535D;
                font-weight: 400;
                @include transitionForAll($time: 0.3s);

                &.Mui-expanded {
                  flex-grow: unset;
                  font-weight: 600;
                }
              }

              .MuiAccordionDetails-root {
                padding: 10px 15px 0 0px;

                .subsection-list {
                  list-style: none;
                  padding-left: 4px;

                  li {
                    margin-bottom: 10px;
                    &:last-child {
                      margin-bottom: 0;
                    }

                    span {
                      color: #00797C;
                    }

                    a {
                      text-decoration: none;
                      padding-left: 18px;
                      margin-left: 0;
                      @include elementStyles($size: 14px,
                        $color: #49535D,
                        $lineHeight: 19px,
                        $weight: 400)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}