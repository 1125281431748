// Importing the fonts styles
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700&subset=latin,latin-ext);


// End of importing the fonts styles
$font_Roboto: 'Roboto', sans-serif;
// Define the fonts variables
$font12: 12px;
$font14: 14px;
$font16: 16px;
$font18: 18px;
$font24: 24px;
$font32: 32px;
$font36: 36px;
$font48: 48px;
// Define the colors variables
$color_red: #D41C54;
$color_title: #49535D;
$color_footer: #4F575C;
$color_text: #68737A;
$color_7D858C: #7D858C;
$color_white: #fff;
$color_0091B3: #0091B3;
$color_green: #00797C;
$color_C0CCD4: #C0CCD4;
$color_949495: #949495;
$color_th_text: #00667D;
$color_td_text: #49535D;

$lightFont:300;
$regularFont:400;
$mediumFont:500;
$semiBoldFont:600;
$boldFont:700;
$asideWidth: 170px;

$boxShadow: 0px 0px 12px rgba(192, 204, 212, 0.5);
// Define the fonts family;
// $Roboto: 'Roboto', sans-serif;
.primary-color{
  color: $color_0091B3;
}
.error-color{
  color: $color_red;
}

.form-group-field{
  position: relative;
  margin-bottom: 15px;
  .MuiFormControl-marginNormal{
    margin: 0;
  }
  .MuiOutlinedInput-input{
    padding: 10px !important;
  }
  .MuiInputBase-root.Mui-disabled{
    opacity: 0.4;
    input{
      color: #000;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: $color_0091B3;
  }
  .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #f44336;
  }
  .MuiInput-underline:after{
    border-bottom: 2px solid $color_0091B3 ;
  }
  .MuiOutlinedInput-multiline{
    padding: 0px;
  }
  .for-label{
    font-size: 14px;
    color: #3e3e3e;
    font-family: 'Roboto';
    display: block;
    margin-bottom: 10px;
  }
  .css-2b097c-container{
    width: 100%;
    .makeStyles-input-179{
      margin-top: -6px;
    }
  }
  .MuiFormControl-root{
    width: 100%;
    background: rgba(192, 204, 212, 0.15);
  }
  .MuiSelect-select{
    padding-top:5px;
    padding-left: 5px;
  }
  .field-wrapper{
    display: flex
  }
  .MuiInputBase-input{
    font-family: 'Roboto';
    font-size: 14px;
    color: $color_text;
    font-weight: $mediumFont;
    &:hover{
      outline: none;
      // border: 1px solid #000;
      // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
    }
    &:focus{
      border-radius: 4px;
    }
  }
  .MuiFormLabel-root{
    font-size: 14px;
    font-family: "Roboto";
    // padding-top: 5px;
  }
  .MuiInputLabel-formControl{
    top: 3px;
    left: 5px;
  }
  .MuiInputAdornment-positionEnd{
    padding-right: 10px
  }
  .MuiFormLabel-root.Mui-focused{
    color: $color_0091B3
  }
  .MuiFormLabel-root.Mui-error {
    color: #f44336;
  }
  .MuiOutlinedInput-adornedEnd{
    padding-right: 3px !important;
  }
  .dropdown-selection{
     width: 100%;
     .css-yk16xz-control, .css-1pahdxg-control{
      background: transparent !important;
    }
    >div{
      // border: none;
      // border-bottom: 1px solid #a1a1a1;
      border-radius: 3px;
      width: 100%;
      cursor: pointer;
      font-family: 'Roboto' !important;
      font-size: 14px !important;
      // background: #F1F4F6;
      padding: 1px 0 ;
      &:hover{
        outline: none;
        // border: 1px solid #000;
        // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
      }
    }&:focus, &:active >div{
        border-radius: 4px;
        // border: 1px solid #009B7C ;
        // box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
        outline: none;
        &:hover{
          border: none;
          outline: none
        }
    }
    &.field-error{
      >div{
        // border: 1px solid #e12525;
      }
    }
    .css-26l3qy-menu {
      *{
        cursor: pointer !important;
      }
    }
  }
  .MuiTextField-root{
    background: rgba(192, 204, 212, 0.15);
  }
  input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="url"], input[type="tel"],input[type="textarea"]{
    width: 100%;
    display: block;
    font-size: 14px;
    color: $color_text;
    font-weight: $mediumFont;
    font-family: 'Roboto';
    outline: none;
    padding: 6px 5px 8px !important;
    &:focus, &:hover, &:active{
      outline: none;
    }
    &.field-error{
      border: 1px solid #e12525;
      &:focus{
        outline: none;
        border: 1px solid #e12525;
      }
    }
  }
  textarea{
    padding-left: 5px;
  }
  .MuiAutocomplete-inputRoot{
    .MuiAutocomplete-input{
      padding-bottom: 7px !important;
    }
  }
  // preserve for pages that part of registration flow
  .react-phone-number-input,
  .PhoneInput {
    width: 100%;

    .PhoneInputCountrySelectArrow{
      color: #000;
    }
    input{
      height: 46px !important;
      border: none;
      border-bottom: 1px solid #929090;
      background: rgba(192, 204, 212, 0.15);
      padding: 0 5px 0 !important;
      &:hover{
        border-bottom: 2px solid #000;
      }
      &:focus,&:active{
        outline: none;
        border-bottom: 2px solid #0091b3;
      }
     &:disabled {
       opacity: 0.5;
     }
    }
    .error{
      border-bottom: 2px solid #e12525;
      &:focus, &:active, &:hover{
        border-bottom: 2px solid #e12525;
        &:hover{
          border-bottom: 2px solid #e12525;
        }
      }
    }
  }
  .error-msg{
    // position: absolute;
    font-size: 11px;
    color: #f44336;
    font-family: 'Roboto';
    margin-top: 5px;
    font-weight: 400;
  }
  input[type="submit"]{
    width: 100%;
    padding: 12px 18px;
    font-weight: $regularFont;
    background: $color_red;
    font-family: $font_Roboto;
    box-shadow: none;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 200px;
    font-size: $font14;
    color: #ffffff;
    &:disabled{
      pointer-events: none;
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px rgba(242,242,242,0.8) inset !important;
}
input:-internal-autofill-selected {
  background: none !important;
}
.MuiInput-underline:after{
  border-bottom: 2px solid $color_0091B3 !important;
}
.MuiInput-underline.Mui-error:after{
  border-bottom-color: #f44336 !important
}
.MuiSelect-select{
  background: transparent !important;
}
.MuiCheckbox-root {
  &:hover{
    background-color: transparent !important;
  }
}
.check-box-span{
  .MuiCheckbox-colorSecondary.Mui-checked:hover{
    background-color: transparent;
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
 .payment-check-box{
   padding: 15px 10px 15px 0px;
   span{
    color: $color_0091B3 !important;
   }
 }
 span{
   font-family: 'Roboto';
   line-height: 14px;
   font-size: 12px;
   font-weight: 400;
   color: $color_text;
   a{
      text-decoration: none;
      color: $color_0091B3 !important;
      font-weight: 500 !important;
      font-size: 12px !important;

    }
   }
}
//autocomplete styling
.MuiAutocomplete-root{
  width: 100%;
  font-family: 'Roboto';
}
.MuiAutocomplete-noOptions{
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
}
.MuiAutocomplete-listbox{
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  background: #0091B3 !important;
  color: #fff !important;
  max-height: 160px !important;
}
.MuiAutocomplete-option{
  // cursor: pointer !important;
  &:hover{
    // color: #ffff;
    // background-color: #67737A !important;

  }
}
.MuiAutocomplete-option[aria-selected="true"]{
  background-color: #33A7C2 !important;
  color: #fff;
  font-weight: 500;
}

.makeStyles-success{
  background-color: #005E70;
}
.MuiSnackbarContent-root{
  font-family: 'Roboto'
}
//dropdown-styling
.MuiMenu-list, .MuiAutocomplete-listbox{
  background: #0091B3;
  max-height: 200px;
  overflow: auto;
  box-shadow: none;
}
.MuiMenuItem-root {
  font-family: 'Roboto' !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 400 !important;
}
.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover{
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #fff;
  font-weight: 500;
}
.MuiListItem-button:hover{
  // background-color: #67737A !important;
  // color: #fff;
}

@mixin elementStyles($size: null, $color: null,  $lineHeight: null, $weight: null, $fontFamily: null, $margin: null, $padding: null){
  @if $size != null {
    font-size: $size;
  }
  @if $lineHeight != null {
    line-height: $lineHeight;
  }
  @if $weight != null {
    font-weight: $weight;
  }
  @if $color != null {
    color: $color;
  }
  @if $fontFamily != null {
    font-family: $fontFamily;
  }
  @if $margin != null {
    margin: $margin;
  }
  @if $padding != null {
    padding: $padding;
  }
}
@mixin transitionForAll($time: 0.2s,$transProp: ease){
  -webkit-transition: all $time $transProp;
  -moz-transition: all $time $transProp;
  -o-transition: all $time $transProp;
  transition: all $time $transProp;
}
// styles to customize lib react-phone-number-input
.registration { 
  .react-phone-number-input,
  .PhoneInput {
    width: 100%;

    .PhoneInputCountrySelectArrow{
      color: #000;
    }
    .PhoneInputCountry {
      margin-right: 8px;
    }
    input{
      padding: 10.5px 14px !important;
      font-size: 16px;
      height: 44px !important;
      border: 1px solid #333E48;
      border-radius: 8px;
      background: #fff;
      box-sizing: border-box;
      background-color: #fff;
      &:focus,&:active,&:not([value=""]){
        outline: none;
        background: #fff !important;
        border: 1px solid #0091B3 !important;
      }
      &:empty {
        background: #E6E7E7;
        border: none;
      }
      &:hover{
        border-width: 1.5px;
        &:empty:not(:focus, :active) {
          background: #CCCFD0;
        }
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    .error{
      border: 1px solid #B70505;
      &:focus, &:active, &:hover{
        border: 1.5px solid #B70505;
        &:hover{
          border: 1.5px solid #B70505;
        }
      }
    }
  }
}