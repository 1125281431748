@import 'variable';

body {
	.dashboard-section {
		.dashboard-container {
			padding: 0;

			.statistics-wrapper {
				height: 100%;
				overflow: hidden;
				background: #0091B3;
				border-radius: 20px;
				box-sizing: border-box;
				padding: 20px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.count-wrapper {
					text-align: left;
					cursor: pointer;
					z-index: 999;

					.count {
						font-weight: $boldFont;
						color: #fff;
						font-size: 20px;
						line-height: 16px;

						>div {
							text-align: left !important;
						}
					}

					p {
						color: #fff;
						font-weight: $mediumFont;
						line-height: 18px;
						margin-bottom: 8px;
					}
				}

				p {
					.primary-btn {
						font-size: 12px;
						padding: 8px 18px;
						box-shadow: 0px 0px 10px #D41C54;
					}
				}

				.bg-img {
					position: absolute;
					left: -20px;
					top: 0;
					z-index: 998;

					&.campaigns {
						left: -26px;
						top: -13px;
					}
				}

				&.partner-campaigns {
					background: #983081;

					.bg-img {
						top: -5px;
						left: -13px;
						transform: rotate(-10deg);
					}

					p {
						.primary-btn {
							background: #0091B3;
							box-shadow: 0px 0px 10px #0389A9;
						}
					}
				}

				&.brand {
					background: $color_green;
				}
			}

			.wrapper {
				padding: 10px 20px;
				border: 1px solid $color_C0CCD4;
				height: fit-content;
				box-sizing: border-box;

				.titleRow {
					margin-bottom: 13px;
				}
			}

			.events-listing {
				.titleRow {
					margin-bottom: 12px;

					h3 {

						img,
						span {
							vertical-align: middle;
						}

						img {
							margin-right: 7px;
							width: 16px;
							height: 16px;
						}

						.primary-btn {
							font-size: 12px;
							padding: 1px 11px;
							border-radius: 3px;
							float: right;
						}
					}
				}
				.tableWrapper {
					max-height: 500px;
					min-height: 350px;
					overflow-x: hidden;
				}
			}

			.events-listing-table {
				min-width: auto;
				.empty-row {
					height: 250px;
				}
			}

			.usecase-listing {
				.tableWrapper {
					max-height: 195px;
					min-height: 195px;
					overflow-x: hidden;
				}
			}

			.use-cases-table {
				min-width: auto;

				.empty-row {
					height: 250px;
				}
			}
		}
	}
}