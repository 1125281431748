@import 'variable';
body{
    .campaigns-listing-section{
        .MuiAutocomplete-root {
          width: unset;
        }
        .heading{
            position: relative;
            .button-wrapper{
              position: absolute;
              right: 0;
              top: 0;
              a{
                display: inline-block;
              }
            }
        }
        .no-campaigns-found{
          min-height: 82vh;
          a{
            float: none;
          }
          .outer-wrapper{
            width: 320px;
            margin-bottom: 30px;
            padding: 30px;
            box-sizing: border-box;
            background: rgba(0, 121, 124, 0.1);
            border: 1px solid #00797C;
            img{
              width: 50px;
              margin-bottom: 12px;
            }
            h3, p{
              @include elementStyles(
                $color: #00797C,
                $lineHeight: 24px
              )
            }
            p{
              font-weight: $regularFont;
              margin-bottom: 20px;
            }
          }
          .bottom-blk{
              a{
                display: inline-block;
              }
          }
        }
      }
}