@import 'variable';
body {
  .initiate-csp-account-section {
    .initiate-csp-account {
      background: #fff;
      .content-blk {
        min-height: 85vh;
        .initiate-csp-wrapper {
          width: 550px;
          box-sizing: border-box;
          background: rgba(0, 121, 124, 0.1);
          border: 1px solid #00797c;
          padding: 30px;
          img {
            width: 50px;
            margin-bottom: 20px;
          }
          h4 {
            @include elementStyles($color: $color_green, $margin: 0 0 25px 0);
          }
          .heading1 {
            &.text {
              width: 80%;
              margin: 0 auto;
              line-height: 24px;
              @include elementStyles(
                $color: $color_green,
                $weight: $regularFont
              );
            }
          }
        }
      }
      .bottom-csp {
        width: 100%;
        text-align: center;
        margin-top: 40px;
      }
    }
  }
}
