@import 'variable';

body {
    .csp-verification-section {
        &.brands {
            .steps-wrapper {
                ul {
                    li {
                        width: 85px;

                        h3.secondaryText {
                            text-align: center !important;
                            overflow: hidden;
                            white-space: nowrap;
                            display: inline;
                        }

                        &::after {
                            left: 74px;
                        }

                        &.trustScore {
                            h3 {
                                margin-left: -8px;
                            }

                            &::after {
                                left: 74px;
                            }
                        }
                    }
                }
            }
        }

        .steps-wrapper {
            ul {
                li {
                    margin-right: 90px;

                    &.dca {
                        margin-right: 70px;

                        &::after {
                            left: 68px;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        form {
            .csp-verification-form {
                margin: 0 64px;
                padding: 0;
                background-color: #fff;
                height: 100%;

                .csp-details-heading {
                    position: relative;
                    margin-bottom: 18px;

                    h3 {
                        margin-top: 2px;
                    }

                    img {
                        position: absolute;
                        top: -3px;
                        left: 0;
                    }
                }

                .csp-details {
                    border: 1px solid #C0CCD4;
                    padding: 25px 30px;
                    margin-bottom: 30px;

                    .form-group-field {
                        margin-bottom: 0;
                    }

                    &.connectivity-partner,
                    &.brandRelationship {
                        .csp-details-heading {
                            .title {
                                position: relative;
                                max-width: 300px;

                                img {
                                    left: 235px !important;
                                    top: 1px !important;
                                }
                            }
                        }

                        &.brandRelationship {
                            .title {
                                img {
                                    left: 180px !important;
                                    top: 1px !important;
                                }
                            }
                        }
                    }

                }

                .css-1uccc91-singleValue {
                    font-family: 'Roboto' !important;
                    font-size: 14px !important;
                    font-weight: 500 !important;
                }

                .csp-terms-and-conditions {
                    margin: 0 auto;
                    max-width: 500px;
                    box-sizing: border-box;
                    border: 1px solid #C0CCD4;

                    .payment-summary {
                        background: rgba(243, 244, 244, 0.75);
                        padding: 20px 30px;

                        .title {
                            margin-bottom: 20px;

                            h3 {
                                margin-left: 10px;
                                display: inline-block;
                            }

                            img {
                                display: inline-block;
                                position: relative;
                                top: 4px;
                            }
                        }

                        .order-summary {
                            .paragraph {
                                line-height: 18px;
                                font-size: 12px;
                                margin-bottom: 20px;
                            }

                            .secondaryText {
                                padding: 15px 13px;
                                text-align: center;
                                line-height: 18px;
                                color: $color_0091B3;
                                border: 1px solid $color_0091B3;
                            }
                        }
                    }

                    .bottom-blk {
                        padding: 30px;

                        p {
                            line-height: 18px;
                            margin-bottom: 15px;
                        }

                        ul {
                            li {
                                input[type="submit"] {
                                    padding: 12px 24px;
                                }

                                p {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-blk {
                ul {
                    text-align: center;

                    li {
                        margin: 0;

                        input[type="submit"] {
                            padding: 12px 27px;
                            min-width: 92px;
                        }

                        padding-left: 12px;
                        padding-right: 12px;
                    }
                }
            }
        }
    }
}