@import "variable";
body{
  @include elementStyles(
    $fontFamily: 'Roboto',
    $lineHeight: 16px,
    $weight: $regularFont,
    $size:$font14,
    $color: $color_text,
  );
  *{
    padding: 0;
    margin: 0;
  }
  .text-center{
    text-align: center
  }
  .list-unstyled{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .list-inline{
    list-style: none;
    li{
      // width: auto;
      display: inline-block;
    }
  }
  .flex-centered{
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    align-items: center;
    &:before,&:after{
      width: 0;
    }
  }

  .flex-not-centered{
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before,&:after{
      width: 0;
    }
  }
  .background-prop{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .flex-item{

  /* this */
  flex: 1 100px;

  /* is the same as */
  flex-grow: 1;
  flex-basis: 100px;

  /* and it leaves the flex-shrink property alone, which would be */
  flex-shrink: inherit; /* defaults to 1 */

  }
  a{
    text-decoration: none;
  }
  .primary-btn, .secondary-btn{
    padding: 12px 18px;
    border-radius: 200px;
    font-family: 'Roboto';
    font-size: $font14;
    cursor: pointer;
    font-weight: $regularFont;
    background: $color_red;
    color: #fff;
    &.disabled{
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
   }
  .secondary-btn{
    padding: 11px 18px ;
    background: #FFF;
    border: 1px solid $color_red;
    color: $color_red;
  }

  .MessageWrapper{
    position: fixed;
    right: 20px;
    top: 150px;
    width: 360px;
    border-radius: 4px;
    z-index: 9999;
    // max-height:
    .Message{
      background: #fff;
      border-radius:5px 5px 0 5px;
      .type{
        display: flex;
        flex-flow: row wrap;
        border-radius:5px 5px 0 5px;
        .Message-icon{
          border-radius: 5px 0 0 5px;
          padding: 20px 5px;
          box-sizing: border-box;
          max-width: 35px;
          display: flex;
          align-items: center;
          img{
            width: 26px;
            height: 18px;
          }
        }
        .Message-body{
          padding-left: 20px;
          padding-right: 40px;
          width: 325px;
          box-sizing: border-box;
          align-self: center;
          word-break: break-word;
        }
        .close-toast{
          position: absolute;
          right: 20px;
          top: 19px;
          cursor: pointer;
          width: 15px;
        }
        &.success,&.SUCCESS{
          background-color: rgba(0, 121, 124, 0.35);
          color: #00797C;
          .Message-icon{
            background: #00797C;
          }
          .Message-body{
            p{
              color: #00797C;
            }
          }
        }
        &.error, &.ERROR{
          background-color: rgba(212, 28, 84, 0.17);
          color: #D41C54;
          .Message-icon{
            background: #D41C54;
          }
          .Message-body{
            p{
              color: #D41C54;
            }
          }
        }
      }
    }
    > *:not(:first-child) {
      margin-top: 10px;
    }
  }

  .heading1{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 19px,
      $weight: $mediumFont,
      $size: $font16,
      $color: $color_title,
    );
  }
  .heading2{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 19px,
      $weight: $regularFont,
      $size: $font12,
      $color: $color_title,
    );
    span{
      font-weight: 400;
      margin-right: 4px;
    }
    &.light{
      font-weight: $regularFont;
    }
  }
  .paragraph{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 16px,
      $weight: $regularFont,
      $size:$font14,
      $color: $color_text,
    );
    span{
      font-weight: $mediumFont
    }
    &.bold{
      font-weight: $mediumFont;
  }
  }
  .secondaryText{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 14px,
      $weight: $regularFont,
      $size:$font12,
    );
  }
  .supportText{
    @include elementStyles(
      $fontFamily: 'Roboto',
      $lineHeight: 16px,
      $weight: $regularFont,
      $size:$font14,
      $color: $color_7D858C,
    );
  }

  .csp-form-footer{
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 0px 12px 30px;
    z-index: 999;
    .check-box-span{
      margin-right: 33px;
      span{
        font-family: 'Roboto';
        color: $color_text
      }
    }
    ul{
      li{
        display: inline-block;
        list-style: none;
        padding-left: 20px;
        &:first-child{
          padding-left: 0;
        }
        .form-group-field{
          margin: 0;
        }
      }
    }
  }

  .MuiAccordion-root{
    background-color: transparent;
    box-shadow: none;
    .MuiAccordionSummary-root{
      min-height: unset;
      font-family: 'Roboto';
      font-size: 14px;
      padding: 0;
      justify-content: flex-start;
    }
    .MuiSvgIcon-root{
      width: 14px;
    }
  }
}
