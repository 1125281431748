@import 'variable';

body {
    .csp-verification-status-section {
        .csp-verification-status-container {
            .csp-status-outer-wrapper {
                min-height: 85vh;

                .verification-status {
                    max-width: 550px;
                    box-sizing: border-box;
                    padding: 30px 15px;

                    .content-blk {
                        margin: 0 10px;
                    }

                    img {
                        height: 60px;
                        margin-bottom: 15px;
                    }

                    p {
                        font-weight: $regularFont;
                        line-height: 24px;
                    }

                    h4 {
                        margin-bottom: 4px;
                    }

                    strong {
                        font-weight: $mediumFont;
                    }

                    &.rejected {
                        background: rgba(212, 28, 84, 0.17);
                        border: 1px solid $color_red;

                        h4,
                        p,
                        span {
                            color: $color_red;
                        }
                    }

                    &.queue {}

                    &.pending {
                        border: 1px solid $color_green;
                        background: rgba(0, 121, 124, 0.1);

                        h4,
                        p,
                        span {
                            color: $color_green;
                        }
                    }

                    &.suspended {
                        border: 1px solid #081F2D;
                        background: rgba(8, 31, 45, 0.9);

                        h4,
                        p,
                        span {
                            color: #fff
                        }
                    }
                }
            }
        }
    }
}