.tcr-menu{
    .MuiPaper-root ul{
        background-color: white;
        padding-top: 0px;
        padding-bottom: 0px;
        li {
            color:#68737A !important;
        }
        li.Mui-disabled {
            color: #C0CCD4 !important;
            opacity: 1;
        }
    }
    .MuiListItem-root {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 16px;
    }
}