@import 'variable';
body {
  .user-authentication-section {
    .auth-banner {
      margin: 30px 20px 0 20px;
      background: #fff;
      .content-wrapper {
        background: #c4c8c9;
        padding: 20px 20px 20px 40px;
        .paragraph {
          @include elementStyles(
            $fontFamily: 'Roboto',
            $lineHeight: 18px,
            $weight: 400,
            $size: 14px,
            $color: #67737a,
            $margin: 0 0 22px 0px
          );
        }
      }
      .footer-wrapper {
        position: relative;
        background: #c4c8c9;
        min-height: 35vh;
        border-radius: 0 0 0 100%;
        margin-top: -5px;
        .inner-wrapper {
          position: absolute;
          bottom: 50px;
          right: 30px;
          text-align: right;
          p {
            @include elementStyles(
              $fontFamily: 'Roboto',
              $lineHeight: 18px,
              $size: 10px,
              $color: #67737a
            );
          }
        }
      }
    }
    .user-form-wrapper {
      min-height: 85vh;
      height: 100%;

      &.auth-thanks {
        background-color: #fff;
        background-clip: border-box;
        overflow: hidden;
      }
    }
    .auth-user-form {
      padding: 20px 40px 25px;
      width: 495px;
      box-sizing: border-box;
      background-color: #ffffff;
      border: 1px solid #e5e5e5;
      input[type='submit'] {
        max-width: 90px;
      }
      .form-title {
        margin-bottom: 20px;
        text-align: center;
        img {
          position: relative;
          width: 23px;
          top: 5px;
          margin-right: 5px;
        }
        h3 {
          @include elementStyles($margin: 0 0 16px 0);
        }
        .paragraph {
          width: 80%;
          margin: 0 auto;
        }
      }
      &.reset-password,
      &.forgot-password {
        .form-title {
          margin-bottom: 30px;
          img {
            width: 20px;
            height: 25px;
          }
        }
        input[type='submit'] {
          margin: 15px 0 0 0;
        }
      }
      ul {
        li {
          &:last-child {
            padding-bottom: 15px;
            border-bottom: 1px solid $color_C0CCD4;
          }
        }
      }
    }
    .account-link-option {
      margin: 20px 0 0 0;
      a {
        @include elementStyles($color: #d41c54, $weight: $boldFont);
      }
      &.forgotpwd {
        text-align: right;
        margin-top: 15px;
        margin-bottom: 30px;
        a {
          color: #0091b3;
          font-weight: $regularFont;
        }
      }
    }
    .campaign-registry {
      text-align: center;
      .header-img {
        padding-top: 40px;
      }
      .link {
        margin-top: 40px;
      }
      .content-blk {
        width: 520px;
        background: rgba(0, 121, 124, 0.1);
        border: 1px solid #00797c;
        padding: 30px;
        img {
          width: 50px;
          margin-bottom: 12px;
        }
        h4 {
          @include elementStyles($color: $color_green, $margin: 0 0 5px 0);
        }
        .heading1 {
          &.text {
            width: 350px;
            margin: 0 auto;
            @include elementStyles($color: $color_green, $weight: $regularFont);
          }
        }
        a {
          display: inline-block;
        }
        span {
          font-weight: 700;
        }
        .spam {
          @include elementStyles(
            $fontFamily: 'Roboto',
            $lineHeight: 22px,
            $weight: 400,
            $size: $font14,
            $color: $color_green,
            $padding: 10px 0 0 0
          );
        }
        .account-link-option {
          font-size: 20px;
          color: #fff;
          margin-top: 80px;
          a {
            font-size: 20px;
          }
        }
      }
    }
  }
  .customTooltipWrapper {
    padding: 20px;
    background: rgba(0, 145, 179, 0.2);
    margin: 0;
    box-shadow: none;
    border-radius: 8px;

    .bellIcon {
      position: absolute;
      right: -12px;
      top: -16px;
    }
    p {
      margin-bottom: 5px;
    }
    ul {
      li {
        padding: 4px 0;
        text-align: left;
        svg,
        span {
          vertical-align: middle;
        }
        svg {
          font-size: 16px;
          margin-right: 5px;
          // fill: $color_green;
          fill: #00d2ad;
          &.clearIcon {
            fill: $color_red;
          }
        }
      }
    }

    .list-suggestion {
      padding-left: 10px;
      li {
        margin-left: 4px;
      }
    }
    .secondaryText {
      color: #0091b3;

      &.boldText {
        font-weight: $boldFont;
      }

      img {
        margin-right: 4px;
      }
      .red {
        color: $color_red;
      }
    }
  }
}
