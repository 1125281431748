@import 'variable';

body {
  .events-listing-section {
    min-height: 82vh;

    .MuiAutocomplete-root {
      width: unset;
    }

    .customCSS.e-date-range-wrapper.e-input-group {
      border: none;
    }

    .heading {
      margin-bottom: 15px;

      h3 {
        font-size: 16px;
      }

      img {
        width: 28px;
        margin-right: 10px;
      }
    }

    .events-listing-table {
      .event-type-wrapper {
        font-weight: 500;
      }
      td {
        &.link {
          cursor: pointer;
          &:hover {
            color: #00667D !important;
            font-weight: bold;
          }
          &:active {
            color: #00667D !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}