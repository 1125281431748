@import 'variable';

body {
  .campaign-register-section {
    .campaign-register-container {
      padding: 0;

      .steps-wrapper {
        .steps-listing {
          li {
            &::after {
              left: 78px;
            }

            margin-right: 53px;

            &.mno {
              margin-right: 57px;

              &::after {
                left: 85px;
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &.selectBrand {
        .no-brands-found-container {
          min-height: 85vh;

          .no-brands-found {
            max-width: 320px;
            box-sizing: border-box;
            padding: 30px;
            background: rgba(0, 121, 124, 0.1);
            border: 1px solid #00797C;

            img {
              width: 50px;
              margin-bottom: 12px;
            }

            margin-bottom: 30px;

            .heading1 {
              color: #00797C;
              line-height: 24px;

              &.light {
                font-weight: $regularFont;
              }
            }
          }

          a {
            display: inline-block;
          }
        }

        .use-cases-listing {
          padding: 20px;
          height: 100%;
          box-sizing: border-box;
          border: 1px solid #C0CCD4;

          .heading {
            margin-top: 5px;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            justify-content: center;

            .heading2 {
              color: #0091B3;
              margin-left: 5px;
            }
          }

          .usecase-list {
            width: 100%;

            .heading-row {
              padding: 12px 10px;
              background: #E5EFF2;
              color: $color_th_text;
              font-weight: $semiBoldFont;
              font-size: 12px;
              border-top-left-radius: 12px;
              border-top-right-radius: 12px;
            }

            .contentRow {
              background: #fff;
              padding: 15px 10px;
              @include transitionForAll($time: 0.3s);

              &:nth-child(odd) {
                background: rgba(243, 244, 244, 0.5);
              }

              p {
                font-weight: $mediumFont;
                font-size: 12px;

                img {
                  width: 12px;
                  margin-right: 5px;
                  position: relative;
                  top: 3px;
                }

                &.fee {
                  // padding-left: 50px;
                }
              }

              .usecase-col {
                display: flex;
                align-items: center;

                .MuiFormControlLabel-root {
                  margin: 0;
                }

                .MuiCheckbox-root {
                  padding: 0 13px 0 5px;

                  &.Mui-disabled {
                    opacity: 0.5;
                  }
                }
              }

              .sub-usecases-listing {
                margin-top: 15px;
                padding-left: 20px;

                // margin-bottom: 5px;
                .sub-usecase-item {
                  .MuiCheckbox-root {
                    .MuiIconButton-label {
                      .material-icons {
                        img {
                          width: 13px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .wrapper {
        border: 1px solid #C0CCD4;
        padding: 25px 30px;
        margin-bottom: 30px;

        .form-group-field {
          margin-bottom: 0;
        }

        .heading-block {
          position: relative;
          margin-bottom: 18px;

          h3 {
            // margin-left: 35px;
            margin-top: 2px;
            font-size: $font18;
          }

          img {
            position: absolute;
            top: -3px;
            left: 0;
          }

          .title {
            position: relative;
            max-width: 300px;

            img {
              left: 270px !important;
              top: 2px !important;
            }
          }
        }

        .description-block {
          margin-bottom: 12px;
          font-size: 12px;
          line-height: 14px;
          font-weight: 400;
          color: #000000;
        }

        &.select-usecase {
          .heading-block {
            h3 {
              span {
                color: $color_0091B3;
                font-weight: $mediumFont;
              }
            }

            .title {
              max-width: unset;
            }
          }
        }

        &.select-brand {}

        &.mno-preview {
          .heading-block {
            img {
              top: 2px;
            }

            margin-bottom: 5px;
          }

          .mno-overview {
            .title {
              margin-bottom: 15px;
            }

            .mno-listing {
              li {
                height: 80px;
                margin-bottom: 10px;

                &:last-child {
                  margin-bottom: 0;
                }

                .name {
                  color: #fff;
                  font-weight: 500;
                  padding: 30px 10px;
                  background: #0091B3;
                  text-align: center;
                  font-size: 12px;
                }

                h3 {
                  color: #0091B3;
                  background: rgba(0, 145, 179, 0.1);
                  padding: 5px 20px 15px;
                  height: 40px;
                  box-sizing: border-box;

                  img {
                    position: relative;
                    top: 4px;
                    margin-right: 5px;
                  }

                  &.light {
                    padding: 20px 20px 17px;
                  }

                  &.tooltip {
                    padding-left: 0;
                    padding-right: 0;

                    span,
                    img {
                      vertical-align: middle;
                    }

                    img {
                      position: relative !important;
                      right: -5px !important;
                      top: -2px !important;
                      width: 8px !important;
                    }

                    span {
                      font-weight: $regularFont;
                    }
                  }
                }

                .paragraph {
                  text-align: center;
                  font-size: 12px;

                  &.bold {
                    font-weight: $semiBoldFont;
                  }

                  &.light {
                    font-weight: $mediumFont;
                    font-size: $font14;
                  }

                  &.tooltip {

                    span,
                    img {
                      vertical-align: middle;
                    }

                    img {
                      position: relative !important;
                      right: -5px !important;
                      top: -2px !important;
                      width: 8px !important;
                      height: 20px;
                      width: 20px;
                    }

                    span {
                      font-weight: $regularFont;
                    }
                  }
                }

                p {
                  word-break: break-all;
                  padding: 12px 0;
                  height: 40px;
                  box-sizing: border-box;
                  background: rgba(243, 244, 244, 0.75);

                  &.status {
                    padding: 13px 0;

                    img {
                      margin-right: 3px;
                    }

                    span {
                      position: relative;
                      bottom: 4px;
                    }
                  }
                }

              }
            }
          }

          &.mno-select-wrapper {
            .mno-listing {
              li {
                position: relative;
                padding-left: 40px;

                .mno-select {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -moz-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -o-transform: translateY(-50%);

                  .material-icons {
                    width: 30px;
                    height: 30px;
                  }

                  &.Mui-disabled {
                    opacity: 0.4;
                  }
                }
              }
            }
          }
        }

        &.campaign-details {
          .form-group-field {
            margin-bottom: 15px;
          }
        }

        &.sample-messages {
          .heading-block {
            img {
              top: 0;
            }
          }

          .message-grid {
            padding-bottom: 0;
          }

          .sample-message {
            &.form-group-field {
              label {
                margin-bottom: 10px;
                display: block;
                color: $color_title;

                img {
                  position: relative;
                  top: 3px;
                  left: 7px;
                  cursor: pointer;
                  @include transitionForAll($time: 0.3s);

                  &:hover {
                    transform: scale(1.1)
                  }
                }
              }
            }
          }

          .add-message-btn {
            margin-top: 15px;
            height: 100%;

            .primary-btn {
              border-radius: 3px;
              display: inline-block;
            }
          }
        }

        &.campaign-attributes {
          .heading-block {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              top: 3px;
            }

            span {
              margin-left: 6px;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              color: #0091B3;
            }
          }

          .campaign-attribute {
            position: relative;
            background: rgba(0, 145, 179, 0.05);
            // margin-bottom: 10px;
            padding: 0px 0 2px 0;
            max-height: 40px;
            box-sizing: border-box;

            p {
              font-weight: $mediumFont;
              color: #0091B3;
              padding-left: 20px;

              img {
                position: relative !important;
                top: 1px !important;
                left: 6px !important;
              }
            }

            .buttons {
              display: flex;
              padding-left: 10px;
            }

            .check-box-span {
              &.yes {
                margin-right: 20px;
              }

              .paragraph {
                // color: #C0CCD4;
                font-size: 14px;
                line-height: 16px;

                &.checked {
                  color: #00797C;
                  font-weight: $boldFont;

                  &.false {
                    color: $color_red;
                  }
                }
              }

            }

            &:last-child {
              margin-bottom: 0;
            }

            .error-msg {
              margin-top: 7px;
              @include elementStyles($fontFamily: 'Roboto',
                $size: 11px,
                $color: #f44336,
                $weight: 400,
                $lineHeight: 12px);
            }

            @include transitionForAll($time: 0.3s);

            &.error {
              margin-bottom: 10px;
            }

            .popup {
              position: absolute;
              right: 0;
              left: 0;
              background: #FFFFFF;
              box-shadow: 0 2px 4px rgba(104, 115, 122, 0.2);
              z-index: 1;

              >* {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 20px;
                background: rgba(0, 145, 179, 0.05);
                border-top: 1px solid rgba(0, 145, 179, 0.3);
                box-sizing: border-box;

                .done {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 80px;
                  height: 25px;
                  margin-top: 20px;
                  font-size: 12px;
                  line-height: 14px;
                  color: #0091B3;
                  background: transparent;
                  border: 1px solid #0091B3;
                  border-radius: 200px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        &.campaign-dca {}
      }

      .bottom-blk {

        // margin-top: 30px;
        ul {
          text-align: center;

          li {
            margin: 0;

            input[type="submit"] {
              padding: 12px 32px
            }

            &:last-child {
              padding-left: 23px;
            }
          }
        }
      }

      .success-note {
        padding: 20px 30px;
        position: relative;
        margin: 0 auto;
        margin-bottom: 30px;
        max-width: 550px;
        box-sizing: border-box;
        background: rgba(0, 121, 124, 0.1);
        border: 1px solid #00797C;
        text-align: center;

        .heading1 {
          color: #00797C;

        }

        img {
          margin-bottom: 12px;

          &.close {
            position: absolute;
            right: 11px;
            top: 11px;
            cursor: pointer;
          }
        }

        p {
          font-weight: $regularFont;
        }
      }

      .next-steps {
        padding: 15px 60px 15px 30px;
        position: relative;
        border: 1px solid #C0CCD4;
        margin-bottom: 30px;
        background: rgba(243, 244, 244, 0.75);

        img {
          position: absolute;
          top: 15px;
          left: 30px;
          width: 24px;
        }

        .content {
          margin-left: 40px;

          .paragraph {
            color: #00797C;
          }

          h3 {
            font-weight: $mediumFont;
            margin-bottom: 3px;
          }

          p {
            font-weight: 300;
          }
        }
      }

      .success-title {
        margin-bottom: 20px;
      }
    }
  }
}

.add-reseller-modal {
  left: 200px !important;

  .add-reseller-content {
    padding: 25px 30px !important;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;

    img {
      margin-right: 3px;
    }
  }

  .form-group-field {
    .error-msg {
      text-align: left;
    }
  }

  ul {
    margin-top: 15px;

    li {
      a {
        display: inline-block;
      }

      input[type="submit"] {
        padding: 12px 25px;
      }

      margin-right: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.create-campaign-modal {
  left: 200px !important;

  .create-campaign-content {
    padding: 40px 50px !important;
  }

  .title {
    margin-bottom: 40px;
  }

  p {
    color: $color_title;

    span {
      font-weight: $boldFont;
    }
  }

  .footer {
    li {
      display: inline-block;
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.tooltip-content {
  span {
    font-size: 18px;
    color: #b11308;
    font-weight: $boldFont;
    margin-right: 5px;
    line-height: 22px;
  }
}