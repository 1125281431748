//mobile portrait view //
@media (min-width: 320px) and ( max-width: 768px){
    body {
        .user-authentication-section{
            .auth-banner{
                margin: 20px 10px;
            }
            .auth-user-form{
                min-width: 250px;
                max-width: 300px;
                padding: 20px 30px;
                &.forgot-password, &.reset-password{
                    max-width: 300px;
                    input[type="submit"]{
                        margin-top: 15px !important;
                    }
                    .form-title{
                        margin-bottom: 20px;
                    }
                }
                .form-title{
                    margin-bottom: 20px;
                    &.register{
                        margin-bottom: 30px;
                    }
                }
                .account-link-option{
                    margin: 10px 0 !important;
                }
                input[type="submit"]{
                    margin-top: 0px ;
                }
                &.register{
                    max-width: 93%;
                    margin: 0 auto;
                    padding: 20px;
                }
            }
            .campaign-registry{
                .content-blk{
                    .paragraph{
                        width: 300px !important;
                    }
                    h4{
                        font-size: 22px;
                    }
                    .account-link-option{
                        margin-top: 40px;
                        font-size: 16px;
                        a{
                            font-size: 18px;
                        }
                    }
                }
            }
             //header
        header{
            box-shadow: 6.439px 6.439px 4.6px rgba(175, 194, 203, 0.251);
            .header-logo{
                justify-content: center;
                width: 100%;
            }
         }
        }
        //header-hoc
        .header-hoc-block{
            padding: 20px;
        }
        .dashboard{
            .register-new-block{
                ul{
                    justify-content: space-between;
                    flex-flow: row;
                    max-width: 300px;
                    li{
                        margin: 0;
                    }
                }
            }
            .dashboard-values{
                // padding: 10px;
            }
            .dashboard-table{
                &::-webkit-scrollbar {
                    width: 4px;
                    cursor: pointer;
                    height: 4px;
                  }
                  &::-webkit-scrollbar-track {
                    background:red;
                    border-radius: 6px;
                    width: 4px;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 6px;
                    background:red; 
                    height: 20px;
                  }
                h3{
                    margin: 0 0 20px 10px;
                }
            }
        }
        .primary-side-menu{
            position: relative;
            top:0;
            width: 230px;
            ul{
                margin-top: 40px;
                li{
                    margin-left: 60px;
                    margin-bottom: 20px;
                }
            }
        }
        .header-drop-down{
            .MuiPopover-paper{
                top: 50px !important;
                right: 15px !important;
            }
        }
        .brand-listing-section{
            a{
                font-size: 10px
            }
            .heading{
                .border{
                    padding: 9px 17px;
                }
            }
            .MuiTablePagination-root{
                 .MuiToolbar-root{
                    flex-flow: row wrap;
                    padding: 0;
                    justify-content: flex-end;
                    .MuiTablePagination-actions{
                        margin-left: 10px;
                        .MuiIconButton-root{
                            padding: 0 5px;
                        }
                    }
                    .MuiTablePagination-selectRoot{
                        margin: 0 8px;
                    }
                }
            }
            
        }
        .campaigns-listing-section{
         
        }
        .events-listing-section{
            .heading{
                margin-bottom: 10px;
            }
        } 
        .filter-wrapper{
            p{
                font-size: 14px;
            }
        }
        .initiate-csp-account{
            padding: 0 10px;
            .csp-flow-diagram{
                padding: 0;
            }
        }
        .csp-portal{
            .csp-verification-form{
                padding: 0;
                .loading-csp-wrapper{
                    padding-top: 0;
                }
                .csp-details-heading{
                    h3{
                        margin: 0 0 10px 0;
                    }
                }
                .csp-details{
                    margin-bottom: 15px;
                }
            }
        }
        .csp-form-footer{
            flex-flow: column wrap;
            padding: 5px !important;
        }
        .modal-wrapper{
            max-height: 500px;
            min-width: 300px;
            overflow: auto;
            padding: 15px;
        }
        .technical-error{
            img{
                width: 100%;
            }
        }
        .brands-register-section{
            .brands-register-form{
                padding: 0;
            }
        }
        .csp-one-time-payment .csp-payment-outer-wrapper{
            width: unset;
            padding: 20px;
            margin: 5px 7px 20px 7px;
        }
        .csp-verification-status .verification-msg-wrapper .Message .type .Message-body p{
            margin: 0;
            font-size: 17px
        }
        .help-section{
            img{
                width: 60px;
            }
            
        }
        .help-section-drawer{
            .MuiDrawer-paper{
                padding-bottom: 40px;
                max-height: 440px !important;
                max-width: 240px !important;
            }
            img{
                left: -59px;
                width: 60px;
            }
            .footer{
                bottom: 10px;
            }

        }
        .filter-section{
            height: unset;
            margin-bottom: 7px;
        }
        .campaign-details-section{
            .brand-details{
                .campaign-info{
                    margin-right: 15px
                }
            }
        }
        .knowledge-base-section{
            .docs-tab-wrapper{
                top: 45px;
            }
        }
      }
    }

//mobile landscape view //
@media (min-width: 576px) and (max-width: 768px) {
    body {
    }
}

