@import 'variable';

body {
    .campaign-detail-section {
        .top-blk {
            margin-bottom: 5px;

            .back-btn {
                cursor: pointer;

                img {
                    margin-right: 3px;
                    position: relative;
                    top: 1px;
                }
            }

            .button-wrapper {
                p {
                    position: relative;

                    a {
                        float: right;
                    }

                    img {
                        right: -13px !important;
                        top: 25px !important;
                        width: unset !important;
                    }
                }
            }
        }

        .campaign-detail-container {
            padding: 0;

            .title {
                text-align: center;
                margin-bottom: 30px;
                h3 {
                    font-size: 22px;
                    line-height: 26px;
                    &.heading1 {
                        color: #49535D;
                    }
                }
            }
            .basic-info{
                width: 100%;
                height: 100%;
                .heading2{
                    font-size: 16px;
                    color: #49535D !important;
                    span{
                        margin-bottom: 0;
                        font-weight: $regularFont;

                        &.val {
                            font-weight: $boldFont;
                            margin-right: 0;
                            word-break: break-word;
                        }
                    }

                    font-weight: $boldFont
                }
                .MuiGrid-item{
                    padding: 0;
                    > .column {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        gap: 11px;
                        margin: 0 auto;
                        width:fit-content;
                        max-width: 80%;
                    }
                    &.border-left{
                        border-left: 1px solid #EDEEEF;
                    }

                    .auto-renewal-wrapper {
                        display: flex;
                        align-items: center;
                        position: relative;

                        p {
                            color: #49535D;
                            font-size: $font16;
                        }

                        .auto-renewal-button {
                            background: rgba(73, 83, 93, 0.12);
                            border-radius: 100px;
                            padding: 4px 5px;
                            color: #D41C54;
                            font-size: 12px;
                            line-height: 14px;
                            width: 50px;
                            box-sizing: border-box;
                            margin-right: 5px;
                            cursor: pointer;
                            @include transitionForAll($time: 0.4s);

                            span {
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                border-radius: 50%;
                                background: #D41C54;
                                position: relative;
                                top: 2px;
                                margin-right: 7px;
                            }

                            &.yes {
                                color: #019AAC;

                                span {
                                    background: #019AAC;
                                    margin-right: 0;
                                    margin-left: 5px;
                                }
                            }

                            &.disabled {
                                cursor: not-allowed;
                                pointer-events: none;
                                opacity: 0.5;
                            }
                        }
                    }
                }

                .campaign-usecase-wrapper {
                    .heading2 {
                        span {
                            &.val {
                                cursor: pointer;
                            }
                        }

                        .dropdown-arrow {
                            vertical-align: middle;
                            position: relative;
                            top: -2px;
                            @include transitionForAll($time: 0.4s);
                            color: #00797C;

                            &.open {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }

            .details {
                border: 1px solid #C0CCD4;
                padding: 25px 30px;
                margin-bottom: 30px;
                word-break: break-word;

                .details-heading {
                    position: relative;
                    padding-bottom: 18px;
                    border-bottom: 1px solid #C0CCD4;
                    margin-bottom: 20px;

                    .edit-btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;

                        img {
                            position: static;
                            vertical-align: middle;
                            margin-right: 5px;
                        }

                        span {
                            color: $color_0091B3;
                            vertical-align: middle;
                        }

                        &.disabled {
                            pointer-events: none;
                            cursor: not-allowed;
                            opacity: 0.5;
                        }
                    }

                    h3 {
                        // margin-left: 35px;
                        // margin-top: 2px;
                        // font-size: 18px;
                        line-height: 21px;
                    }

                    img {
                        position: absolute;
                        top: 2px;
                        left: 0;
                    }
                }

                &.mno {
                    padding-right: 15px;
                    &.details {
                        margin-top: 50px;
                    }
                    .details-heading{
                        padding-bottom: 0;
                        border: none;

                        img {
                            // top: 3px;
                        }
                    }

                    .mno-overview {
                        // max-height: 210px;
                        padding-right: 5px;

                        .mno-listing {
                            li {
                                margin-bottom: 10px;

                                &:last-child {
                                    margin: 0;
                                }

                                .name {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #fff;
                                    font-weight: 500;
                                    padding: 0 10px;
                                    background: #0091B3;
                                    text-align: center;
                                    font-size: 12px;
                                }

                                h3 {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    color: #0091B3;
                                    background: rgba(0, 145, 179, 0.1);
                                    height: 40px;
                                    box-sizing: border-box;

                                    img {
                                        margin-right: 5px;
                                    }

                                    &.light {
                                        padding: 20px 20px 17px;
                                    }

                                    &.tooltip {
                                        padding-left: 0;
                                        padding-right: 0;

                                        span,
                                        img {
                                            vertical-align: middle;
                                        }

                                        img {
                                            position: relative !important;
                                            right: -5px !important;
                                            top: -2px !important;
                                            width: 8px !important;
                                        }

                                        span {
                                            font-weight: $regularFont;
                                        }
                                    }
                                }

                                .paragraph {
                                    text-align: center;
                                    font-size: 12px;

                                    &.bold {
                                        font-weight: $semiBoldFont;
                                    }
                                }

                                p {
                                    word-break: break-all;
                                    padding: 16px 0;
                                    height: 50px;
                                    box-sizing: border-box;
                                    background: rgba(243, 244, 244, 0.75);

                                    &.status {
                                        padding: 13px 0;

                                        img {
                                            margin-right: 3px;
                                        }

                                        span {
                                            position: relative;
                                            bottom: 4px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.attributes {
                    .details-heading {
                        padding-bottom: 0;
                        border: none;
                    }

                    .campaign-attribute {
                        position: relative;
                        background: rgba(0, 145, 179, 0.05);

                        // margin-bottom: 10px;
                        .view-container {
                            height: 50px;
                        }

                        .title {
                            margin: 0;
                            text-align: left;
                        }

                        p {
                            font-weight: $mediumFont;
                            color: #0091B3;
                            padding-left: 20px;

                            img {
                                position: relative !important;
                                top: 1px !important;
                                left: 6px !important;
                            }
                        }

                        .buttons-view {
                            .view-item {
                                display: inline-block;
                                margin-right: 12px;

                                img {
                                    position: relative;
                                    top: 3px;
                                    width: 15.47px;
                                    height: 15px;
                                    margin-right: 5px;
                                    object-fit: contain;
                                }

                                span {
                                    font-weight: $boldFont;
                                    color: #C0CCD4;
                                }
                            }

                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .error-msg {
                            margin-top: 7px;
                            @include elementStyles($fontFamily: 'Roboto',
                                $size: 11px,
                                $color: #f44336,
                                $weight: 400,
                                $lineHeight: 12px);
                        }

                        .buttons {
                            display: flex;
                            flex-direction: row;
                            gap: 12px;
                        }

                        .check-box-span {
                            .MuiFormControlLabel-root {
                                margin: 0;
                            }

                            .payment-check-box {
                                padding-right: 5px;
                            }

                            .paragraph {
                                // color: #C0CCD4;
                                font-size: 14px;
                                line-height: 16px;

                                &.checked {
                                    color: #00797C;
                                    font-weight: $boldFont;

                                    &.false {
                                        color: $color_red;
                                    }
                                }
                            }

                        }
                    }
                }

                &.description {
                    .details-heading {
                        img {
                            top: 5px;
                        }
                    }
                }

                &.sample-messages {
                    .sample-message-item {
                        margin-bottom: 17px;
                        position: relative;

                        .message-count {
                            position: absolute;

                            h3 {
                                padding: 2px 4.5px 1px;
                                background: #00797C;
                                border-radius: 3px;
                                @include elementStyles($color: #fff,
                                    $weight: 500,
                                    $size: 14px)
                            }
                        }

                        p {
                            word-break: break-word;
                            margin-left: 30px;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }

                &.campaign-events {
                    .campaign-detail-events-table {
                        td {
                            height: 40px;
                            padding: 0 10px 0 16px;
                        }
                        .empty-row {
                            height: 240px;
                        }
                        .event-type-wrapper {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .elect-cnp-alert-wrapper {
            .MuiSnackbar-root {
                top: 237px;
                right: 0;

                .MuiPaper-root {
                    background: #00797C;
                    padding: 15px;
                    border-radius: 14px 0 0 14px;
                    width: 300px;
                    box-sizing: border-box;

                    .MuiSnackbarContent-message {
                        padding: 0;
                        width: 100%;
                    }
                }
            }

            .alert-content-wrapper {
                display: flex;
                align-items: center;
                position: relative;

                .alert-icon {
                    margin-right: 8px;
                    transform: rotate(15deg);
                    font-size: 26px;
                }

                .close-icon {
                    position: absolute;
                    top: -7px;
                    right: 3px;
                    cursor: pointer;
                    opacity: 0.5;
                    font-size: 20px;
                }

                .alert {

                    h3,
                    p {
                        color: $color_white;
                    }

                    p {
                        font-size: $font12;
                    }

                    h3 {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .details.other-responsible-parties {
        .details-heading {
            width: 100%;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            height: 32px;
            margin-bottom: 10px;

            img {
                margin-right: 5px;
                position: relative;
                top: 0;
            }

            .heading1 {
                margin-left: 0;
                flex-grow: 1;
            }
        }

        .reseller-container {
            .title {
                text-align: left;
                margin: 0;

                p {
                    color: $color_red;
                    font-weight: 500;

                    &.reseller {
                        color: $color_0091B3;
                    }
                }

                .value {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 5px;
                    }

                    span {
                        font-weight: 500;
                    }
                }

                button {
                    border-radius: 3px;

                    .MuiSvgIcon-root {
                        font-size: 16px;
                    }
                }
            }

            .info {
                width: 100%;
                margin-top: 20px;

                .paragraph {
                    font-weight: 500;
                    line-height: 1.025rem;

                    span {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .cnp-dca-details {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color_C0CCD4;

        .cnp-details-wrapper {
            display: flex;
            align-items: center;

            .elect-cnp-btn {
                cursor: pointer;
                background: $color_0091B3;
                border-radius: 3px;
                width: 120px;
                box-sizing: border-box;
                padding: 10px;
                position: relative;
                height: 32px;

                .icon,
                span {
                    vertical-align: middle;
                }

                span {
                    color: $color_white;
                    font-size: 12px;
                    margin-right: 5px;
                }

                .alert-icon {
                    position: absolute;
                    background: $color_red;
                    border-radius: 50%;
                    right: -10px;
                    top: -10px;
                    font-size: 18px;
                    transform: rotate(30deg);
                    color: #fff;
                    padding: 2px;
                }
            }
        }

        .dca-election-info {
            padding: 8px 0;

            img,
            span {
                vertical-align: middle;
            }

            img {
                margin-right: 8px;
            }

            &.pending {
                color: $color_red;
            }

            &.completed {
                color: $color_green;
            }
        }
    }
}

.edit-modal {
    .edit-content {
        .title {
            .heading1 {
                // margin-left: 30px;
            }

            img {
                width: 25px;
                top: -1px;
            }

            &.description-title {
                img {
                    width: 18px;
                    top: 3px;
                }
            }
        }

        .sample-message-wrapper {
            .message-item {
                margin-bottom: 20px;

                .form-group-field {
                    label {
                        margin-bottom: 8px;
                        display: block;
                        color: #49535D;

                        span,
                        img {
                            vertical-align: middle;
                        }

                        img {
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .add-message-btn {
                p {
                    a {
                        color: #0091B3;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &.campaign {
        .edit-content {
            .footer {
                padding-top: 10px;
            }
        }
    }

    &.auto-renewal {
        .paragraph {
            color: #49535D;
            margin-bottom: 30px;

            span {
                font-weight: $boldFont;
            }
        }

        .footer {
            padding-top: 30px;

            li {
                margin-right: 15px;

                &:last-child {
                    margin: 0;
                }

                p {
                    a {
                        width: 90px;
                    }
                }
            }
        }
    }

    &.deactivate {
        .footer {
            padding-top: 0;
        }
    }
}

.campaign-subusecases-menu {
    .MuiMenu-paper {
        margin-top: 33px !important;
        border-radius: 2px;

        // box-shadow: none;
        .MuiMenu-list {
            background: #00797C;
            padding: 0;

            li {
                cursor: text;

                &:nth-child(even) {
                    background: rgba(255, 255, 255, 0.4);
                }
            }
        }
    }
}

.reseller-select-modal {
    .details {
        margin-top: 10px;

        .details-heading {
            font-weight: 500;
            font-size: 20px;
            line-height: 1.17;
            color: #071822;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 5px;
            }
        }
    }

    p.paragraph {
        margin-top: 10px;
        color: #49535D;
        font-weight: 400;
    }

    .reseller-selector {
        .MuiAutocomplete-root {
            .MuiInputLabel-root {
                color: #49535D;
                margin-left: 5px;

                &.MuiInputLabel-shrink {
                    font-size: 12px;
                    color: #00698F;
                    margin-top: 3px;
                }
            }

            .MuiFormControl-root {
                background-color: #F6F7F8;

                .MuiInput-root {
                    font-size: 14px;
                    padding-left: 5px;
                }
            }
        }
    }
}
