@import 'variable';

body {
    .resellers-section {
        .resellers-container {
            padding: 0;

            .heading {
                position: relative;
                height: 60px;

                .button-wrapper {
                    position: absolute;
                    right: 0;
                    top: 0;

                    a {
                        display: inline-block;
                    }
                }
            }

            .resellers-listing {
                td {
                    height: 40px;
                    word-break: break-all;
                    padding-top: 0;
                    padding-bottom: 0;
                }
                .edit-col {
                    margin-top: 2px;
                    float: right;

                    svg {
                        cursor: pointer;
                        @include transitionForAll($time: 0.3s);
                        vertical-align: baseline;

                        &:hover {
                            transform: scale(1.1)
                        }
                        &.edit {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .no-resellers-found {
                min-height: 82vh;

                a {
                    float: none;
                }

                .outer-wrapper {
                    width: 320px;
                    margin-bottom: 30px;
                    padding: 30px;
                    box-sizing: border-box;
                    background: rgba(0, 121, 124, 0.1);
                    border: 1px solid #00797C;

                    img {
                        width: 50px;
                        margin-bottom: 12px;
                    }

                    h3,
                    p {
                        @include elementStyles($color: #00797C,
                            $lineHeight: 24px)
                    }

                    p {
                        font-weight: $regularFont;
                        margin-bottom: 20px;
                    }
                }

                .bottom-blk {
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .delete-reseller-modal {
        left: 200px !important;
    }

    .delete-reseller-content {
        padding: 58px;
        margin: 0 auto;
        min-width: 430px;

        .dialog-wrapper {
            margin-bottom: 36px;
            text-align: center;

            .heading2 {
                span {
                    font-weight: 700;
                }
            }
        }

        ul {
            text-align: center;

            li {
                display: inline-block;
                margin-right: 15px;

                &:last-child {
                    margin: 0;
                }

                .primary-btn {
                    padding: 12px 21px
                }
            }
        }
    }

}