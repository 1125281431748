@import 'variable';

body {
  .brand-listing-section {
    .MuiAutocomplete-root {
      width: unset;
    }

    .heading {
      position: relative;

      .button-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;

        a {
          display: inline-block;
        }

        .ein-search-wrapper {
          margin-right: 24px;
          min-width: 300px;

          .MuiTextField-root {
            .MuiFilledInput-underline {

              &::after,
              &::before {
                // border-color: transparent;
                border-bottom: none;
              }
            }

            .MuiFilledInput-adornedStart {
              background: #F3F4F4;
              padding-left: 8px;
              border-radius: 0;

              .MuiInputAdornment-root {
                margin-right: 5px;
                margin-top: 0px;

                img {
                  cursor: pointer;

                  &:hover {
                    transform: scale(1.2);
                    @include transitionForAll($time: 0.5s);
                  }
                }
              }

              input {
                box-sizing: border-box;
                padding: 11px 5px;
                height: 40px;
                @include elementStyles($fontFamily: 'Roboto',
                  $size: 14px,
                  $weight: 400,
                  $color: #49535D,
                  $lineHeight: 16px)
              }
            }
          }

          .error-msg {
            position: absolute;
            font-size: 11px;
            color: #f44336;
            margin-top: 2px;
            font-weight: 400;
          }
        }
      }

      .filter-section {
        .filter-button {
          // margin-bottom: 30px;
        }
      }
    }

    .no-brands-found {
      min-height: 82vh;

      a {
        float: none;
      }

      .outer-wrapper {
        width: 320px;
        margin-bottom: 30px;
        padding: 30px;
        box-sizing: border-box;
        background: rgba(0, 121, 124, 0.1);
        border: 1px solid #00797C;

        img {
          width: 50px;
          margin-bottom: 12px;
        }

        h3,
        p {
          @include elementStyles($color: #00797C,
            $lineHeight: 24px)
        }

        p {
          font-weight: $regularFont;
          margin-bottom: 20px;
        }
      }

      .bottom-blk {
        a {
          display: inline-block;
        }
      }
    }
  }

  .brands-listing-table {
    tbody tr td {
      padding: 12px 8px 0 16px;
    }
  }
}