@import "variable";

body {
  .text-trucate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flex {
    display: flex;

    &.space-between {
      justify-content: space-between;
    }

    &.flex-column {
      flex-direction: column;
    }
  }

  .h-100vh {
    height: 100vh;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-2 {
    flex: 2;
  }

  .pointer {
    cursor: pointer;
  }

  // User authnication module scss
  background: #FFFFFF !important;
  ;

  .switch-wrapper {
    position: relative;
  }

  .switch-wrapper>div {
    position: absolute;
  }

  padding: 0;
  margin: 0;
  min-height: 100vh;

  // Header Bar style
  .left-top-logo{
    background: #002C36;
    justify-content: center;
    width: $asideWidth;
    box-sizing: border-box;
    position: fixed;
    z-index: 1002;
    height: 70px;
    top: 0;
    left: 0;
    a {
      //  height: 40px;
      img {
        width:90px;
        margin-top: 16px;
        margin-left: 16px;
      }
    }
  }
  header {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // background: linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%);
    background: #fff;
    border-bottom: 1px solid #E6E7E7;
    // z-index: 1000;
    box-sizing: border-box;

    .flex-centered {
      height: 49px !important;
      padding: 0 !important;
    }

    .header-logo {
      height: 100%;
      background: #001D24;
      justify-content: center;
      width: $asideWidth;
      box-sizing: border-box;

      a {
        img {
          width:90px;
          margin-top: 4px;
          margin-left: 8px;
        }
      }
    }

    .user-dropdown {
      position: absolute;
      right: 26px;

      .header-nav-menu {
        cursor: pointer;
        padding: 6px 12px;
        border-radius: 100px;
        span {
          font-size: 14px;
          font-weight: 800;
          color: #333E41;
        }
        img{
          height: 17.1px;
        }
        &.active, &:hover {
          background-color: #F2F3F3;
        }
      }

      ul {
        float: right;
        list-style: none;
        cursor: pointer;

        li {
          display: inline-block;
          margin-right: 10px;
          @include elementStyles($fontFamily: 'Roboto',
            $size: $font14,
            $color: $color_title,
            $weight: 300);

          &:nth-child(2),
          &:last-child {
            margin-right: 6px;
          }

          & span {
            font-family: 'Roboto';
            font-weight: 500;
          }
        }
      }
    }

    h3 {
      @include elementStyles($margin: 7px 0 0 0)
    }

    .header-avatar {
      position: relative;

      h2 {
        color: #fff;
        font-weight: 400;
        position: absolute;
        font-family: 'Roboto';
        top: 3px;
        right: 14px;
        font-size: 12px;
      }
    }
  }

  //mobile-header
  .mobile-header {
    background: #001D24;
    box-shadow: 0px 6.3px 4.5px 0 rgba(7, 24, 34, 0.25);
    z-index: 999;

    .menu-icon {
      color: #001D24;
      font-size: 45px;
      padding-left: 5px;
    }

    path {
      color: #fff;
    }

    .user-dropdown {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      padding-right: 10px;

      ul {
        li {
          color: #fff;
          margin-right: 5px;

          .user-avator {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    position: fixed;
    top: 0px;
    width: 100%;
    height: 50px;

    .MuiGrid-item {
      height: 100%;
    }

    .header-logo {
      width: 100%;

      a {
        img {
          width: 175px;
          margin: 4px 0 0 -10px;
        }
      }
    }
  }

  //side-menu-bar styling
  .primary-side-menu {
    background: #002C36;
    position: fixed;
    box-sizing: border-box;
    width: $asideWidth;
    top: 49px;
    left: 0px;
    z-index: 999;
    height: calc(100% - 49px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon-border{
      padding: 6px;
      width:24px;
      height:24px;
      border: 2px #0091B3 solid;
      border-radius: 50%;
      font-size: 12px;
    }
    .active {
      .icon-border{
        // padding: 6px;
        font-size: 12px;
        background: #0091B3;
      }
    }
    a {
      font-size: 14px;
      font-weight: 400;
      &:visited{
        color: white;
      }
    }
    .active {
      color:white;
      a {
        font-size: 14px;
        font-weight: 800;
        &:visited{
          color: inherit;
        }
      }
    }
    .header-logo {
      a {
        img {
          width: $asideWidth;
        }
      }
    }

    .last-login-wrapper {
      margin: 0 23px 30px;

      .bottom-menu {
        color: white;
        font-weight: $semiBoldFont;
        &:hover {
          color: #0091B3;
          font-weight: 600;
        }
      }
      h3 {

        img,
        span {
          vertical-align: middle;
        }

        .icon-block {
          width: 20px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
        }

        margin-bottom: 15px;
      }

      hr {
        margin: 8px 0;
        border: 0.5px solid $color_white;
        opacity: .6;
      }

      h5 {
        color: $color_white;
        font-size: $font12;
        font-weight: $regularFont;
        line-height: 1.2;
        margin-bottom: 5px;
      }

      .content {
        font-size: $font12;
        font-weight: 600;
      }

      p {
        color: #fff;
      }
    }

    ul {
      margin-top: 35px;

      li {
        margin-left: 15px;

        a,
        .multi-nav {
          cursor: pointer;
          display: inline-block;
          margin-bottom: 20px;
          @include transitionForAll($time: 0.4s);
          font-size: 14px;
          color: white;
          &:hover {
            color:white;
            font-weight:800;
          }
          a {
            font-weight:400;
            font-size: 14px;
            &:visited{
              color: white;
            }
          }
          .active {
            font-weight:800;
            color:white;
            a {
              font-size: 14px;
              font-weight:800;
              &:visited{
                color: inherit;
              }
            }
          }

          img {
            margin-right: 10px;
            width: 16px;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
            color: #FFF;
            font-size: 14px;
            @include transitionForAll($time: 0.4s);
          }

          .expand-icon {
            color: #fff;
            vertical-align: middle;
            width: 20px;
          }

          &:hover {
            font-weight:800;
            &.multi-nav {
              margin-bottom: 20px;
            }

            .dropdown-list {
              display: block;
            }

            span {
              color: white;
              font-size: 14px;
              font-weight: 800;
            }

            .expand-icon {
              color: #0091B3;
              transform: rotate(180deg);
              @include transitionForAll($time: 0.4s);
            }
          }
        }

        &.active {

          a {
            &.multi-nav {
              margin-bottom: 20px;
              font-weight:800;
            }

            span {
              font-weight: 800;
              color: white;
            }

            .expand-icon {
              color: white;
              transform: rotate(180deg);
              @include transitionForAll($time: 0.4s);
            }
          }

          .dropdown-list {
            display: block;
          }
        }

        .dropdown-list {
          display: none;
          margin-top: 15px;

          li {
            margin-left: 15px;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: #FFF;
              font-size: 14px;
              font-weight: 300;
              margin-bottom: 0;
              font-weight: 400;
              .dot {
                width: 3px;
                display: inline-block;
                margin-right: 8px;
                height: 3px;
                background: $color_red;
                border-radius: 50%;
              }
            }

            &.active-sub-nav,
            &:hover {
              a {
                .dot {
                  background: #fff;
                }
              }
            }

            &.active-sub-nav {
              a {
                font-weight: $mediumFont;
              }
            }
          }
        }
      }
    }
  }

}

//hoc-styling
.header-hoc-block{
  width: 100%;
  padding: 30px 30px 25px 25px;
  box-sizing: border-box;

  &.form{
    padding: 17px 30px 0px 0px;
  }

  &.csp {
    padding: 25px 30px 25px 30px
  }
}

.footer-hoc-block {
  font-size: 10px;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  color: $color_footer;
  height: 60px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  &.form {
    padding: 17px 30px 0px 240px;
  }

  &.csp {
    padding: 25px 30px 25px 30px
  }

  div {
    display: flex;
    justify-content: center;

    p {
      max-width: 802px;

      a {
        cursor: pointer;
        color: $color_footer;
        text-decoration: underline;
      }
    }
  }
}

//Dashboard styling
.custom-scrollbar {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    color: #C4C8C9;
    background: #F2F2F2;
    border-radius: 6px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #C4C8C9;
    height: 20px;
  }
}

//Loader-styling
.mesh-loader {
  overflow: hidden;
  height: 100%;
  width: 100%;

  &.hoc {
    >div {
      margin-left: 0px;
    }
  }
}

.mesh-loader .circle {
  width: 25px;
  height: 25px;
  position: absolute;
  // background: linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%);
  background: #0091B3;
  border-radius: 50%;
  margin: -12.5px;
  -webkit-animation: mesh 3s ease-in-out infinite;
  animation: mesh 3s ease-in-out infinite -1.5s;
}

.mesh-loader>div .circle:last-child {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.mesh-loader>div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 100px;
}

.mesh-loader>div:last-child {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  50% {
    -webkit-transform-origin: 50% -100%;
    transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  50.00001% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform-origin: 50% 200%;
    transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.MuiMenuItem-root {
  min-height: 40px !important;
  line-height: 0 !important;
  flex-flow: row;
  @include elementStyles($fontFamily: 'Roboto' !important,
    $size: $font14 !important);
  @include transitionForAll($time: 0.4s);
}

.header-drop-down{
  .MuiPopover-paper{
    top: 45px !important;
    right: 30px !important;
    left: auto !important;
    max-width: 200px;
    min-width: 146px;

    &.MuiPaper-rounded{
      border-radius: 8px;
    }
  }

  .MuiMenu-list {
    outline: none;
    background: #0091B3 !important;

  }

  .MuiMenuItem-root {
    min-height: 25px !important;
    line-height: 0 !important;
    flex-flow: row;
    width: 100%;
    justify-content: flex-start;
    opacity: 0.8;
    border-bottom: 0.5px solid #0091B3;
    @include elementStyles($fontFamily: 'Roboto' !important,
      $weight: 400 !important,
      $size: 14px !important,
      $color: white !important
    );
    @include transitionForAll($time: 0.4s);
    &:hover{
      font-weight: 600 !important;
      opacity: 1;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  .MuiList-padding{
    padding: 6px 0px;
  }
  .MuiMenuItem-gutters{
    padding: 15px 12px;
  }

  .MuiMenu-list {
    background: 'unset';
  }
  .MuiListItem-button:hover{
    background-color: #00748F !important;
  }
}

.page-not-found-section {
  .page-not-found-container {
    min-height: 100vh;
    background: #0091B3;

    .page-not-found-wrapper {
      position: relative;
    }

    img {
      width: 120px;
      position: absolute;
      left: -130px;
      top: -65px;
    }

    .content-blk {
      h3 {
        color: #fff;
        font-size: 72px;
        line-height: 72px;
      }

      p {
        color: #fff;
        max-width: 190px;
        text-align: center;
        margin-bottom: 60px;
      }

      a {
        display: inline-block;
      }
    }
  }
}

.no-access-section {
  .no-access-container {
    padding: 0;
    display: flex;
    align-items: center;
    flex-flow: column wrap;
    justify-content: center;
    min-height: 80vh;

    h3 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    .heading2 {
      font-size: 16px;
      margin-bottom: 32px;
    }

    a {
      display: inline-block;
    }
  }
}

//technical-error styling
.technical-error {
  position: absolute;
  top: 50%;
  left: 56%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;

  // margin-top: 68px;
  img {
    margin-bottom: 30px;
  }

  h3 {
    margin-bottom: 20px;
    @include elementStyles($fontFamily: 'Roboto',
      $size: 22px,
      $color: $color_title,
    );
  }

  p {
    @include elementStyles($fontFamily: 'Roboto',
      $size: $font14,
      $color: $color_text,
    );
    margin-bottom: 35px;
  }
}

// SideNavigation styling
.side-navigation {
  &.registration {
    & > div:nth-child(2) > div:first-child {
      a[href] {
        opacity: 0.1;
        pointer-events: none;
      }
    }
  }
}

//table-styling
.tableWrapper {
  table {
    tbody {
      tr {
        &.link {
          cursor: pointer;
        }

        // for event table description expand / collapse
        .MuiAccordion-root {
          box-shadow: none;
          background: transparent;
          .MuiAccordionSummary-root {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 14px;
          }

          .MuiAccordionSummary-root.Mui-expanded {
            word-break: break-all;
            padding: 12px 0;
            align-items: flex-start;
          }

          .MuiAccordionSummary-content {
            color: $color_td_text;
            margin: 0;
            font-size: 12px;
            @include transitionForAll($time: 0.3s);
            word-break: break-all;

            &.Mui-expanded {
              word-break: break-word;
            }
          }

          .MuiAccordionSummary-expandIcon {
            padding: 0 8px;
            margin-right: -8px;
          }
        }
      }
    }
  }
}

.pagination-wrapper {
  margin-top: 15px;
  width: 100%;
  text-align: center;

  .pagination {
    margin-top: 20px;

    ul {
      justify-content: center;

      li {
        button {
          margin: 0;
          border-radius: 0;
          @include elementStyles($fontFamily: 'Roboto', $size: 13px, $weight: 400, $color: #000, $lineHeight: 16px);
          // opacity: 0.5;
          background: $color_white;
          border-color: transparent;

          .MuiSvgIcon-root {
            fill: #0091B3;
            font-size: 25px
          }

          &.Mui-selected {
            opacity: 1;
            border-radius: 50%;
            border: 1px solid #0091B3;
            background: rgba(0, 145, 179, 0.13);
            color: #0091B3;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.filter-section {
  .filter-button {
    position: relative;
    background: #fff;
    border: 1px solid #0091B3;
    border-radius: 200px;
    padding: 11px 15px;
    cursor: pointer;
    max-width: 85px;
    margin-bottom: 20px;
    @include transitionForAll($time: 0.4s);

    img {
      position: absolute;
      left: 15px;
      width: 14px;
    }

    p {
      margin-left: 20px;
      font-weight: 500;
      color: #0091B3;
    }

    &.open {
      background: #0091B3;

      p {
        color: #fff;
      }
    }
  }

  // .filter-item{
  //   // margin-right: 20px;
  //   &.drop-down{
  //     .MuiInput-underline:before{
  //       // border-bottom: unset !important;
  //     }
  //   }
  //   .MuiTypography-colorTextSecondary{
  //     color: #47545D
  //   }
  //   .filter-label{
  //     .MuiInputLabel-formControl{
  //       top: -16px;
  //       font-size: 14px;
  //       color: #47545D;
  //       font-family: 'Roboto Condensed'
  //     }
  //     label + .MuiInput-formControl{
  //       margin-top: 0;
  //     }
  //   }
  //   // label{
  //     cursor: pointer;
  //     display: flex;
  //     align-items: center;
  //     .filter-item-checkbox{
  //       span{
  //         color: #0091b3 !important;
  //       }
  //       padding: 10px 0px;
  //       margin-right: 5px;
  //       .MuiSvgIcon-root{
  //         width: 0.8em !important;
  //       }
  //     }
  //     .checkbox-title{
  //       color: #47545D;
  //       width: 100%;
  //     }
  //   // }
  //   .MuiCheckbox-colorSecondary.Mui-checked:hover{
  //     background-color: transparent;
  //   }
  //   .MuiIconButton-colorSecondary:hover {
  //     background-color: transparent;
  //   }

  //  span{
  //    @include elementStyles(
  //      $fontFamily: 'Roboto Condensed',
  //      $size:14px,
  //      $color: #47545D
  //    );
  //   }
  //    //react-select-styling
  //   .filter-react-select{
  //     width: 88% !important;
  //     margin: 0 0 0 30px !important;
  //     font-family: 'Roboto Condensed' !important;
  //     font-size: 14px !important;
  //     font-weight: 400 !important;
  //     }
  //    .css-yk16xz-control{
  //      &:hover{
  //       outline: none;
  //       border: 1px solid #929090;
  //       box-shadow: 0 6px 6px 0 rgba(0, 171, 173, 0.09);
  //       cursor: text !important;
  //     }
  //    }
  //   .css-1pahdxg-control{
  //     border-color: #0091b3 !important;
  //     box-shadow: 0 0 0 1px #0091b3;
  //     cursor: text !important;
  //   }
  //   //mui select styling
  //   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  //     border-color: #00abad;
  //   }
  //   .MuiFormControl-marginNormal{
  //     margin: 0px !important;
  //   }
  //    .MuiInputBase-fullWidth {
  //     // margin: 0 0 0 30px !important;
  //     // width: 88% !important;
  //     .MuiInputBase-input{
  //       font-family: 'Roboto Condensed';
  //       font-size: 14px;
  //       font-weight: 400;
  //     }
  //     .MuiOutlinedInput-input{
  //       padding: 11px 14px;
  //     }
  //   }
  // }
  .filter-outer-wrapper {
    border: 1px solid #C0CCD4;
    box-sizing: border-box;
    padding: 15px 20px;
    margin-bottom: 20px;

    .filter-item {
      &.form-group-field {
        position: relative;
        width: 100%;
        margin: 0;

        label {
          margin-bottom: 7px;
          display: inline-block;
        }
      }

      .remove-icon {
        width: 15px;
        position: absolute;
        right: 5px;
        top: 22px;
        cursor: pointer;
        @include transitionForAll($time: 0.3s);

        &:hover {
          transform: scale(1.1)
        }
      }
    }

    .filter-bottom {
      display: flex;
      width: 100%;
      margin-top: 20px;
      justify-content: flex-end;
      align-items: center;

      li {
        &:first-child {
          margin-right: 20px;
        }

        a {
          display: inline-block;
        }
      }

      .primary-btn {
        padding: 7px 18px;
      }

      .secondary-btn {
        padding: 5px 18px;
        border: 2px solid $color_red;
      }

    }
  }
}

.filter-applied {
  background-color: #fff;
  margin-bottom: 4px;

  ul {
    position: relative;
    padding-right: 116px !important;

    li {
      display: inline-block;
      margin: 0 16px 16px 0;
      padding: 2px 10px 5px;
      border-radius: 200px;
      border: 1px solid $color_text;
      position: relative;
      padding-right: 28px;
      padding-top: 4px;
      @include elementStyles($color: $color_title,
        $weight: $mediumFont );

      & span {
        margin-left: 5px;
        color: $color_text;
        font-weight: $regularFont;
        word-break: break-word;
      }

      .filter-close {
        margin-left: 7px;
        cursor: pointer;
        @include transitionForAll($time: 0.3s);
        width: 14px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);

        &:hover {
          transform: scale(1.3) translateY(-50%);
          -webkit-transform: scale(1.3) translateY(-50%);
          -moz-transform: scale(1.3) translateY(-50%);
          -ms-transform: scale(1.3) translateY(-50%);
          -o-transform: scale(1.3) translateY(-50%);
        }
      }

      &.clear-filter {
        border: 1px solid $color_red;
        color: $color_red;
        padding: 4px 10px;
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        top: 0;
        margin: 0;
        right: 0;
      }
    }
  }
}

//help-section-drawer
.help-section {
  img {
    position: fixed;
    top: 90px;
    width: 70px;
    right: -2px;
    height: 60px;
    cursor: pointer;
  }
}

.help-section-drawer {
  .MuiDrawer-paper {
    padding: 80px 24px 30px 20px;
    background: #fff;
  }

  .MuiDrawer-paperAnchorRight {
    top: 90px;
    box-sizing: border-box;
  }

  .MuiPaper-elevation16 {
    box-shadow: none;
  }

  img {
    position: absolute;
    top: 0;
    left: -68px;
    cursor: pointer;
    width: 70px;
    height: 60px;
  }

  .help-section-wrapper {
    overflow: auto;
    padding-bottom: 15px;

    h3 {
      @include elementStyles($margin: 0 0 5px 0);

      &.title {
        position: absolute;
        top: 0px;
        left: 0;
        color: #fff;
        background: #081F2D;
        width: 100%;
        padding-left: 20px;
        line-height: 60px;
      }
    }

    .content-blk {
      >div {
        margin-bottom: 20px;

        h3 {
          @include elementStyles($fontFamily: 'Roboto',
            $lineHeight: 16px,
            $weight: $mediumFont,
            $size: $font14,
            $color: $color_title,
          );
        }

        p {
          @include elementStyles($fontFamily: 'Roboto',
            $lineHeight: 16px,
            $weight: $regularFont,
            $size: $font14,
            $color: $color_text,
          );

          strong {
            font-weight: $mediumFont;
            color: $color_title;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 8px;

    .paragraph {
      font-weight: $mediumFont;

      span {
        color: #0091B3;
        cursor: pointer
      }
    }
  }
}

//steps-wrapper
.steps-wrapper {
  padding: 5px 0;
  margin-bottom: 25px;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      margin-right: 70px;
      position: relative;

      .step {
        // width: 29px;
        // height: 29px;
        position: relative;
        // border-radius: 50%;
        margin: 0 auto 3px auto;
        @include elementStyles($fontFamily: 'Roboto',
          $color: $color_red,
          $size: $font14,
          $weight: $mediumFont,
          $lineHeight: 12px);

        span {
          // position: absolute;
          // left: 10px;
          // top: 8px;
          display: inline-block;
          border-radius: 50%;
          padding: 8px 10px;
          border: 2px solid $color_red;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 16px;
        left: 56px;
        height: 1px;
        width: 110px;
        background: #A0A8AC;
      }

      h3 {
        text-align: left;
      }

      &.active {
        .step {
          color: $color_white;

          span {
            background: $color_red;

          }
        }

        h3 {
          font-weight: $mediumFont;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }

        margin-right: 0;
      }
    }
  }
}

.default-dialog {
  left: $asideWidth !important;
}

.brand-confirmation-dialog {
  left: $asideWidth !important;

  .brand-confirmation-dialog-content {
    padding: 40px 60px !important;
  }

  .title {
    margin-bottom: 40px;
  }

  p {
    color: $color_title;

    span {
      font-weight: $boldFont;
    }
  }

  .footer {
    li {
      display: inline-block;
      margin-right: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }

  &.ein-error {
    .title {
      .paragraph {
        line-height: 20px;

        span {
          font-weight: $boldFont;
        }
      }
    }

    .footer {
      li {
        p {
          a {
            display: inline-block;
            min-width: 80px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.success-modal {
  // z-index: 999 !important;
  left: $asideWidth !important;

  &.mno-disclaimer,
  &.emergency-alert {
    .success-content-blk {
      a {
        width: 80px;
        box-sizing: border-box;
      }
    }
  }

  &.carrier-exemption-alert {
    .success-content-blk {
      .footer {
        li {
          padding-right: 15px;

          &:last-child {
            padding-right: 0;
          }

          a {
            min-width: 90px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  &.emergency-alert {
    .MuiDialog-container {
      .MuiDialog-paper {
        max-width: 495px;

        .success-content-blk {
          p {
            color: $color_text;
          }
        }
      }
    }
  }

  &.general-alert {
    .success-content-blk {
      .title {
        margin-bottom: 15px;
      }

      a {
        width: 80px;
        box-sizing: border-box;
      }
    }
  }
}

.success-content-blk {
  background: #fff;
  padding: 30px !important;

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 12px;
    object-fit: cover;
  }

  .heading2 {
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #49535D;
  }

  .paragraph {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #49535D;
  }

  a {
    display: inline-block;
    margin-top: 28px;
  }
}

.brand-relationship-wrapper {
  margin-top: 50px;

  .relationship-item {
    border-radius: 10px;
    cursor: pointer;
    min-width: 160px;
    max-width: 20% !important;
    border: 2px solid transparent;
    position: relative;
    flex-basis: 19% !important;
    @include transitionForAll($time: 0.4s);

    img {
      width: 50px;
      position: absolute;
      left: 35%;
      top: -35px;
    }

    .top-blk {
      max-height: 60px;
      text-align: center;
      padding: 30px 0px 12px;
      background: rgba(0, 145, 179, 0.1);
      border-radius: 10px 10px 0 0;
    }

    .content-blk {
      text-align: center;
      height: 105px;
      padding: 20px 15px;
      box-sizing: border-box;
      background: rgba(243, 244, 244, 0.75);
      border-radius: 0 0 10px 10px
    }

    &.active {
      border: 2px solid #0091B3;
    }

    &:hover {
      border: 2px solid #0091B3;
    }

    &.disabledOption {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.error-msg-brand-relationship {
  font-size: 11px;
  color: #f44336;
  font-family: 'Roboto';
  margin-top: 5px;
  font-weight: 400;
}

.terms-conditions-wrapper {
  .tc-button {
    text-align: center;
    margin-bottom: 30px !important;

    a {
      cursor: pointer;
      font-weight: 300;
      background: #D41C54;
      border-radius: 3px;
      color: #fff;
      padding: 7px 10px;
      display: inline-block;

      span {
        font-weight: $mediumFont;
      }
    }
  }
}

.temrs-conditions-dialog {
  .heading2 {
    margin-bottom: 25px;
    font-size: 18px;

    img,
    span {
      vertical-align: middle;
      font-weight: $mediumFont;
    }

    img {
      margin-right: 5px;
    }

    .close-icon {
      color: $color_0091B3;
      float: right;
      cursor: pointer;
    }
  }

  .paragraph {
    line-height: 18px;
  }

  .terms-conditions-container {
    padding: 40px 40px;

    .content-blk {
      padding: 0 20px;

      &.custom-scrollbar {
        &::-webkit-scrollbar-thumb {
          height: 90px;
        }
      }

      .content {
        margin-bottom: 30px;
        text-align: justify;

        p {
          line-height: 18px;
          margin-bottom: 1em;
        }

        ul {
          margin-bottom: 20px;

          li {
            margin-bottom: 1em;
          }
        }
      }

      .exhibit-blk {
        width: 100%;
        margin-bottom: 0;

        h3 {
          @include elementStyles($fontFamily: 'Roboto',
            $lineHeight: 19px,
            $weight: $mediumFont,
            $size: $font16,
            $color: $color_title,
          );
          margin-bottom: 25px;
        }

        @mixin list-style {
          li {
            margin-bottom: 25px;
            margin-left: 30px;
            @include elementStyles($fontFamily: 'Roboto',
              $lineHeight: 18px,
              $weight: $regularFont,
              $size: $font14,
              $color: $color_text,
            );

            &:last-child {
              margin: 0;
              margin-left: 30px;
            }
          }
        }

        ol {
          list-style-position: inside;
          margin-top: 20px;
          margin-bottom: 20px;

          @include list-style;
        }

        ul {
          margin-top: 20px;
          margin-bottom: 20px;
          list-style: disc;

          @include list-style;
        }

        &.a {
          margin-top: 50px;
        }

        &.b,
        &.c {
          margin-bottom: 10px;

          p {
            margin-bottom: 18px;
            @include elementStyles($fontFamily: 'Roboto',
              $lineHeight: 18px,
              $weight: $regularFont,
              $size: $font14,
              $color: $color_text,
            );
          }
        }

        table {
          margin-bottom: 30px;

          thead {
            tr th {
              text-align: center;
            }
          }

          tbody {
            tr {

              td,
              th {
                padding: 10px 20px 10px 40px;
                @include elementStyles($fontFamily: 'Roboto',
                  $lineHeight: 16px,
                  $weight: $regularFont,
                  $size: $font14,
                  $color: $color_text,
                );

                p {
                  margin-bottom: 0 !important;
                }
              }

              th {
                padding: 10px 20px;
                font-weight: $mediumFont;
                color: $color_title;
              }
            }
          }
        }
      }
    }

    .footer-blk {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 25px 0px 25px;
      text-align: center;
      background: #fff;
      margin-left: -40px;

      ul {
        li {
          margin-right: 20px;

          a {
            display: inline-block;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }
}

.elect-cnp-dialog {
  left: $asideWidth !important;

  .MuiDialog-paperWidthSm {
    max-width: 525px;
    width: 525px;
  }

  .content-wrapper {
    min-width: 370px;
    padding: 20px 30px;

    .title {
      margin-top: 20px;
      margin-bottom: 20px;

      .heading1 {
        font-size: 20px;
      }
    }

    .paragraph {
      padding: 7px 0 11px;
      color: #49535D;
      font-size: 14px;
      line-height: 16.4px;
      font-weight: 400;
    }

    .note {
      color: #49535D;
      margin: 7px 0;
      font-size: 10px;
      line-height: 12px;
      font-weight: 400;
    }

    .footer {
      text-align: center;
      padding-top: 10px;

      li {
        &.form-group-field {
          margin-bottom: 0;

          input[type="submit"] {
            padding: 12px 15px 12px 14px;
          }
        }

        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
}

.reject-campaign {
  .title {
    margin-bottom: 20px;

    img {
      position: relative;
      right: 5px;
      top: 1px;
    }
  }
}

.auto-suggestions-dropdown {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: 125px;
  z-index: 999;
  overflow: scroll;
  margin-top: 3px;
  border-radius: 4px;

  ul {
    background: #fff;
    padding: 0;

    li {
      // background: $color_menu;
      background: $color_0091B3;
      word-break: break-word;
      color: #fff;
      font-size: 12px;
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 145, 179, 0.8);
      }

      &.noOptions {
        cursor: text;
      }
    }
  }
}

.custom-switch-wrapper {
  background: rgba(73, 83, 93, 0.12);
  border-radius: 100px;
  padding: 4px 5px;
  color: #D41C54;
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;
  @include transitionForAll($time: 0.4s);

  span {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    background: #D41C54;
    position: relative;
    top: 2px;
    margin-right: 7px;
  }

  &.yes {
    color: #019AAC;

    span {
      background: #019AAC;
      margin-right: 0;
      margin-left: 5px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

#iubenda-cs-banner.iubenda-cs-bottom {
  .iubenda-cs-container {
    .iubenda-cs-content {
      height: 55vh !important;
      display: flex !important;
      align-items: center !important;

      .iubenda-cs-rationale {}
    }
  }
}

@media (min-height: 0) and (max-height: 660px) {
  body {
    .primary-side-menu {
      .last-login-wrapper {
        position: static;
      }
    }
  }
}